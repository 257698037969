import React from "react";
import GetInTouchSection from "../common/GetInTouch/GetInTouchSection";
import BacktoTopCom from "../helpers/BackToTopCom";
import Layouts from "../helpers/Layouts";
import WorkProcessSection from "./ServiceOne/WorkProcessSection";

export default function ServicesLayout({ children, pageTitle, breadcrumbs }) {
  return (
    <Layouts pageTitle={pageTitle} breadcrumbs={breadcrumbs}>
      {children && children}
      <WorkProcessSection />
      <GetInTouchSection />
      <BacktoTopCom />
    </Layouts>
  );
}
