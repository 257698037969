import React from "react";
import Layouts from "../helpers/Layouts/index";
import MainSection from "./MainSection";

export default function PrivacyPolicy() {
  return (
    <Layouts
      pageTitle="Privacy Policy"
      breadcrumbs={[
        { name: "home", path: "/" },
        { name: "Privacy Policy", path: "/privacy-policy" },
      ]}
    >
      <MainSection />
    </Layouts>
  );
}
