import React, { useState } from "react";
import DataIteration from "../helpers/DataIteration";

export const MERNstack = () => {
    return (
        <div className="about-content rpr-0 wow fadeInRight delay-0-2s">
            <div className="section-title mb-35">
                <h2>
                    Master the MERN Stack and Build Powerful Web Applications
                </h2>
                <span className="sub-title">
                    In Progress...
                </span>
            </div>
            {/* <p>
                A strong online presence is crucial for businesses in the modern digital era, and effective web development plays a pivotal role in shaping and maintaining that presence.
            </p>
            <ul className="list-style-one mt-15">
                <li>Global Reach and Visibility</li>
                <li>Verified Lead Generation</li>
                <li>24/7 Accessibility</li>
                <li>Brand Visibility and Credibility</li>
                <li>Marketing and Promotion</li>
                <li>Customer Engagement and Interaction</li>
                <li>Competitive Edge</li>
                <li>Data Collection and Analysis</li>
                <li>Credibility and Trust</li>
            </ul> */}
        </div>
    )
}

export const FrontEnd = () => {
    return (
        <div className="about-content rpr-0 wow fadeInRight delay-0-2s">
            <h2>
                Comming Soon
            </h2>
        </div>
    )
}

export const Backend = () => {
    return (
        <div className="about-content rpr-0 wow fadeInRight delay-0-2s">
            <h2>
                Comming Soon
            </h2>
        </div>
    )
}

export const WebDevelopment = () => {
    return (
        <div className="about-content rpr-0 wow fadeInRight delay-0-2s">
            <h2>
                Comming Soon
            </h2>
        </div>
    )
}

function CoursesTab() {
    const tabContent = [
        {
            id: 1,
            uniqKey: "_one",
            content: "MERN Stack Development",
            tabContent: <MERNstack />
        },
        {
            id: 2,
            uniqKey: "_two",
            content: "Front End Developer",
            tabContent: <FrontEnd />
        },
        {
            id: 3,
            uniqKey: "_three",
            content: "Backend Developer",
            tabContent: <Backend />
        },
        {
            id: 4,
            uniqKey: "_three",
            content: "Web Development",
            tabContent: <WebDevelopment />
        },
    ];

    const [tab, setTab] = useState(tabContent[0].id);
    const tabHandler = (e, value) => {
        e.preventDefault();
        setTab(value);
    };

    return (
        <div className="row">
            <div className="col-lg-3">
                <ul className="widget widget-services">
                    <DataIteration datas={tabContent} startLength={0} endLength={4}>
                        {({ datas }) => (
                            <li key={datas.id + datas.uniqKey} className={tab === datas.id ? "active" : ""}>
                                <a
                                    onClick={(e) => tabHandler(e, datas.id)}
                                    data-toggle="tab"
                                    href="#"
                                >
                                    {datas.content}
                                </a>
                            </li>
                        )}
                    </DataIteration>
                </ul>
            </div>
            <div className="col-lg-9 tab-content">
                <DataIteration datas={tabContent} startLength={0} endLength={4}>
                    {({ datas }) => (
                        <div
                            key={datas.id + datas.uniqKey}
                            className={`tab-pane fade ${tab === datas.id ? "show active" : ""
                                }`}
                            id="tabContent1"
                        >
                            {datas.tabContent}
                        </div>
                    )}
                </DataIteration>
            </div>
        </div>
    );
}

export default CoursesTab;
