import React, { useState } from "react";
import DataIteration from "../helpers/DataIteration";

export const WebDevelopment = () => {
    return (
        <div className="about-content rpr-0 wow fadeInRight delay-0-2s">
            <div className="section-title mb-35">
                <h2>
                    Revolutionize Your Online Presence with Our Web Development Services
                </h2>
                <span className="sub-title">
                    Importance & Web Development Contribution
                </span>
            </div>
            <p>
                A strong online presence is crucial for businesses in the modern digital era, and effective web development plays a pivotal role in shaping and maintaining that presence.
            </p>
            <ul className="list-style-one mt-15">
                <li>Global Reach and Visibility</li>
                <li>Verified Lead Generation</li>
                <li>24/7 Accessibility</li>
                <li>Brand Visibility and Credibility</li>
                <li>Marketing and Promotion</li>
                <li>Customer Engagement and Interaction</li>
                <li>Competitive Edge</li>
                <li>Data Collection and Analysis</li>
                <li>Credibility and Trust</li>
            </ul>
        </div>
    )
}

export const DigitalMarketing = () => {
    return (
        <div className="about-content rpr-0 wow fadeInRight delay-0-2s">
            <div className="section-title mb-35">
                <h2>
                    Fueling Your Brand Growth with Data-Driven Digital Marketing
                </h2>
                <span className="sub-title">
                    Drive Online Success with Our Digital Marketing Expertise
                </span>
            </div>
            <h6>
                Benefits of Digital Marketing Services:-
            </h6>
            <ul className="list-style-one mt-15">
                <li>Increased website traffic and qualified leads</li>
                <li>Improved brand awareness and lead conversion rates</li>
                <li>Higher ROI for marketing campaigns</li>
                <li>Enhanced lead nurturing and customer engagement</li>
                <li>Data-driven insights and strategic optimization</li>
            </ul>
            <h6 className="mt-60">
                Specific Services we Offered:-
            </h6>
            <ul className="list-style-one mt-15">
                <li>Search Engine Optimization (SEO)</li>
                <li>Pay-Per-Click (PPC) advertising for Google Ads, LinkedIn Ads, etc.</li>
                <li>Content marketing with blog posts, white papers, and case studies</li>
                <li>Social media marketing for LinkedIn, Twitter, and industry-specific platforms</li>
                <li>Email marketing campaigns for lead generation and nurturing</li>
                <li>Website analytics and conversion optimization</li>
                <li>Marketing automation for personalized and automated campaigns</li>
                <li>Industry-specific digital marketing strategies</li>
            </ul>
        </div>
    )
}

export const SEOMarketing = () => {
    return (
        <div className="about-content rpr-0 wow fadeInRight delay-0-2s">
            <div className="section-title mb-35">
                <h2>
                    Dominate Search Engines with our Expert SEO
                </h2>
                <span className="sub-title">
                    SEO benefits it brings to businesses, such as increased website traffic, qualified leads, and higher sales.
                </span>
            </div>
            <h6>
                Search Engine Optimization Services:-
            </h6>
            <ul className="list-style-one mt-15">
                <li>Keyword research and analysis</li>
                <li>On-page optimization (title tags, meta descriptions, content optimization)</li>
                <li>Off-Page Optimization (title tags, meta descriptions, content optimization)</li>
                <li>Technical SEO audits and improvement</li>
                <li>Link building and outreach</li>
                <li>Content marketing and strategy</li>
                <li>Local SEO optimization for specific regions</li>
                <li>Analytics and reporting</li>
            </ul>
        </div>
    )
}

export const GraphicDesigning = () => {
    return (
        <div className="about-content rpr-0 wow fadeInRight delay-0-2s">
            <div className="section-title mb-35">
                <h2>
                    Crafting Pixel-Perfect Designs for Your Brand
                </h2>
                <span className="sub-title">
                    Transform Ideas into Visual Masterpieces with Our Graphic Design Services
                </span>
            </div>
            <h6>
                Key Graphic Designing Services
            </h6>
            <ul className="list-style-one mt-15">
                <li>Logo design</li>
                <li>Website and app design</li>
                <li>User interface (UI) and user experience (UX) design</li>
                <li>Social media graphics and ads</li>
                <li>Marketing materials and presentations</li>
                <li>Infographics</li>
                <li>UI/UX Design</li>
                <li>Branding and Identity Design</li>
            </ul>
        </div>
    )
}

export const CloudServices = () => {
    return (
        <div className="about-content rpr-0 wow fadeInRight delay-0-2s">
            <div className="section-title mb-35">
                <h2>
                Unlock Scalability and Agility with our Cloud Solutions
                </h2>
                <span className="sub-title">
                Cloud Services advantages for businesses, such as increased efficiency, cost optimization, and greater flexibility
                </span>
            </div>
            <h6>
            Key Cloud Services
            </h6>
            <ul className="list-style-one mt-15">
                <li>Infrastructure as a Service (IaaS): servers, storage, networks</li>
                <li>Platform as a Service (PaaS): development tools, databases, middleware</li>
                <li>Software as a Service (SaaS): pre-built applications, web-based services</li>
                <li>Cloud migration and consulting</li>
                <li>Cloud security and compliance solutions</li>
                <li>Data backup and disaster recovery services</li>
                <li>Hybrid Cloud Solutions</li>
                <li>Serverless Computing</li>
            </ul>
        </div>
    )
}

function ServicesTab() {
    const tabContent = [
        {
            id: 1,
            uniqKey: "_one",
            content: "Web Development",
            tabContent: <WebDevelopment />,
        },
        {
            id: 2,
            uniqKey: "_two",
            content: "Digital Marketing",
            tabContent: <DigitalMarketing />
        },
        {
            id: 3,
            uniqKey: "_three",
            content: "SEO Marketing",
            tabContent: <SEOMarketing />
        },
        {
            id: 4,
            uniqKey: "_four",
            content: "Graphic Designing",
            tabContent: <GraphicDesigning />
        },
        {
            id: 5,
            uniqKey: "_five",
            content: "Cloud Services",
            tabContent: <CloudServices />
        }
    ];

    const [tab, setTab] = useState(tabContent[0].id);
    const tabHandler = (e, value) => {
        e.preventDefault();
        setTab(value);
    };

    return (
        <div className="row">
            <div className="col-lg-3">
                <ul className="widget widget-services">
                    <DataIteration datas={tabContent} startLength={0} endLength={5}>
                        {({ datas }) => (
                            <li key={datas.id + datas.uniqKey} className={tab === datas.id ? "active" : ""}>
                                <a
                                    onClick={(e) => tabHandler(e, datas.id)}
                                    data-toggle="tab"
                                    href="#"
                                >
                                    {datas.content}
                                </a>
                            </li>
                        )}
                    </DataIteration>
                </ul>
            </div>
            <div className="col-lg-9 tab-content">
                <DataIteration datas={tabContent} startLength={0} endLength={5}>
                    {({ datas }) => (
                        <div
                            key={datas.id + datas.uniqKey}
                            className={`tab-pane fade ${tab === datas.id ? "show active" : ""
                                }`}
                            id="tabContent1"
                        >
                            {datas.tabContent}
                        </div>
                    )}
                </DataIteration>
            </div>
        </div>
    );
}

export default ServicesTab;
