import React from "react";
import ServicesLayout from "../ServicesLayout";
import MainSection from "./MainSection";

export default function Services() {
  return (
    <ServicesLayout
      pageTitle="Our Services"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Our Services", path: "/services" },
      ]}
    >
      <MainSection />
    </ServicesLayout>
  );
}
