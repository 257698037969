import { Route, Routes } from "react-router-dom";
import AboutSeven from "./components/AboutPages/AboutSeven";
import Blog from "./components/BlogPages/Blog";
import BlogDetails from "./components/BlogPages/BlogDetails";
import Contact from "./components/Contact/index";
import ServiceDetails from "./components/ServicePages/ServiceDetails/index";
import ProtfolioDetails from "./components/PortfolioPages/PortfolioDetails/index";
import FourZeroFour from "./components/FourZeroFour/index";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsConditions from "./components/TearmsConditions";
import HomeTwo from "./components/Home";
import Services from "./components/ServicePages/Services";

export default function Routers() {
  return (
    <Routes>
      <Route exact path="/" element={<HomeTwo />} />
      <Route exact path="/about" element={<AboutSeven />} />
      <Route exact path="/services" element={<Services />} />
      <Route exact path="/online-courses" element={<ServiceDetails />} />
      <Route exact path="/training" element={<ProtfolioDetails />} />
      <Route exact path="/blog" element={<Blog />} />
      <Route exact path="/blog/blog-details" element={<BlogDetails />} />
      <Route exact path="/404" element={<FourZeroFour />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route exact path="/terms-conditions" element={<TermsConditions />} />
    </Routes>
  );
}
