import React from "react";


export default function MainSection() {
  return (
    <section className="about rel z-1 pt-150 rpt-30 pb-120">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="about-content">
              <div className="section-title mb-30">
                <span className="sub-title">Next Cosmos Technology Pvt Ltd - Privacy Policy</span>
                <h2>Effective Date: 22/05/2024</h2>
                
              </div>
              <h4>Introduction</h4>
              <p>
              Next Cosmos Technology Pvt Ltd we respects the privacy of our users you. This Privacy Policy describes the types of information we collect, how we use it, and how we protect it.
              </p>

              <h3>Information We Collect</h3>
              <h4>We collect the following types of information:</h4>
              <p>
              Personal Information: This may include your name, email address, phone number, and other information you choose to provide.
              Non-Personal Information: This may include information about your device, browsing activity, and usage of our services. This information is collected automatically through cookies and other tracking technologies.
              </p>

              <h3>How We Use Your Information</h3>
              <h4>We use your information for the following purposes:</h4>
              <p>
                To provide and improve our services.
                To communicate with you.
                To personalize your experience.
                To analyze how our services are used.
                To comply with legal and regulatory requirements.
              </p>
              <h3>Your Choices</h3>
              <h4>You have choices regarding your information:</h4>
              <p>
                You can access and update your personal information.
                You can opt out of receiving marketing communications from us.
                You can choose to disable cookies in your browser settings.
              </p>

              <h4>Data Security</h4>
              <p>
              We take reasonable steps to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no internet transmission is completely secure.
              </p>

              <h4>Children's Privacy</h4>
              <p>
                Our services are not directed to children under the age 18 years. We do not knowingly collect personal information from children.
              </p>

              <h4>Changes to This Privacy Policy</h4>
              <p>
              We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website.
              </p>

              <h4>Contact Us</h4>
              <p>
              If you have any questions about this Privacy Policy, please contact us at info@nextcosmostechnology.com.
              </p>

            </div>
          </div>
        </div>
      </div>
      <div className="circle-drop"></div>
    </section>
  );
}
