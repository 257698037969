import React from "react";
import Layouts from "../helpers/Layouts/index";
import MainSection from "./MainSection";

export default function TearmsConditions() {
  return (
    <Layouts
      pageTitle="Terms and Conditions"
      breadcrumbs={[
        { name: "home", path: "/" },
        { name: "Terms and Conditions", path: "terms-conditions" },
      ]}
    >
      <MainSection />
    </Layouts>
  );
}
