import React from "react";
import GetInTouchSection from "../common/GetInTouch/GetInTouchSection";

import BacktoTopCom from "./BackToTopCom";
import Layouts from "./Layouts";

export default function InnerPageDefaultLayout({
  children,
  pageTitle,
  breadcrumbs = [],
}) {
  return (
    <Layouts pageTitle={pageTitle} breadcrumbs={breadcrumbs}>
      {children && children}
      <GetInTouchSection />
      <BacktoTopCom />
    </Layouts>
  );
}
