import React from "react";
import background from "../../../assets/images/background/progress.png";

export default function WorkProcessSection() {
  return (
    <section
      className="work-progress-three bg-blue text-white pt-115 rpt-95 pb-85 rpb-65"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="container">
        <div className="section-title text-center mb-35">
          <span className="sub-title">How We Do</span>
          <h2>
            Custom IT Solutions <br />
            for Your Business
          </h2>
        </div>
        <div className="work-progress-inner-three">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-6">
              <div className="progress-item-two style-two wow fadeInUp delay-0-2s">
                <span className="progress-step">01</span>
                <div className="icon">
                  <i className="flaticon flaticon-seo"></i>
                </div>
                <h3>
                  Advertising and <br />
                  Marketing
                </h3>
                <p>
                  We provide services for brand marketing, advertising, and product promotion in addition to helping to establish brands through conceptualization.
                </p>
                {/* <a href="/online-courses" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a> */}
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="progress-item-two style-two wow fadeInUp delay-0-4s">
                <span className="progress-step">02</span>
                <div className="icon">
                  <i className="flaticon flaticon-web-development"></i>
                </div>
                <h3>
                  Web <br />
                  Development
                </h3>
                <p>
                  Our website developers offer customers professional web application development and web design services.
                </p>
                {/* <a href="/online-courses" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a> */}
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="progress-item-two style-two wow fadeInUp delay-0-6s no-border mb-0">
                <span className="progress-step">03</span>
                <div className="icon">
                  <i className="flaticon flaticon-code"></i>
                </div>
                <h3>
                Industrial Training
                </h3>
                <p>
                In an industrial training course , you will learn about various types of technologies, including those used in networking, design, and telecommunication.
                </p>
                {/* <a href="/online-courses" className="learn-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
