import React, { useEffect, useRef } from 'react';
import video from '../../assets/videos/slide.mp4'

const VideoPlayer = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    // Access the DOM element directly and set the playbackRate property
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.25;
    }
  }, []);

  return (
    <video autoPlay muted loop id="bgVideo" ref={videoRef}>
      <source src={video} type="video/mp4" />
    </video>
  );
};

export default VideoPlayer;
